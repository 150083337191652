<script>
import Layout from "../../layouts/auth";

import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Login component
 */
export default {
    page: {
        title: "Login",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
    },
    data() {
        return {
            work_permit_id: this.$route.params.work_permit_id,
            data_detail: null,
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            // Get Data Detail
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit", {
                    params: {
                        id: this.work_permit_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        var data_edit = response_data_fix.data[0];
                        this.data_detail = data_edit;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data.data[0];
                    console.log(this.axiosCatchError);
                });
        },
        checkpdf() {
            let self = this;
            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit?id=" + self.work_permit_id, "_blank");
        },
    },
};
</script>
<style scoped lang="scss">
body {
    background: url("../../../assets/images/bg_bayer_login1.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
<template>
    <Layout>
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card overflow-hidden w-100">
                    <div class="card-body">
                        <table class="table table-sm table-striped table-borderles">
                            <tbody>
                                <tr>
                                    <th style="width: 250px">Work Permit Number</th>
                                    <th style="width: 10px">:</th>
                                    <td>{{ data_detail?.work_permit_no }}</td>
                                </tr>
                                <tr>
                                    <th style="width: 250px">Work Permit Date</th>
                                    <th style="width: 10px">:</th>
                                    <td>{{ data_detail?.work_permit_date }}</td>
                                </tr>
                                <tr>
                                    <th style="width: 250px">Initiator Name</th>
                                    <th style="width: 10px">:</th>
                                    <td>{{ data_detail?.user?.name }}</td>
                                </tr>
                                <tr>
                                    <th style="width: 250px">Work Permit Status</th>
                                    <th style="width: 10px">:</th>
                                    <td>{{ data_detail?.status_permit }}</td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <b-button type="button" variant="success" class="btn btn-block" style="margin-top: 10px" v-on:click="checkpdf()"><i class="fa fa-file"></i> Download PDF</b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- end card -->

                <!-- end row -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>
